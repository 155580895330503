<template>
  <div class="contBox fatherHeight">
    <div class="bedList-serach flex-between">
      <div class="serach-left">
        <el-form
          :inline="true"
          :model="tableSearch"
          ref="searchForm"
          class="demo-form-inline w-144"
        >
          <el-input
            v-model="tableSearch.search_code"
            class="hospital w-208"
            placeholder="搜索"
          >
            <template #suffix>
              <i
                style="cursor: pointer"
                @click="getDataList"
                class="fa fa-search"
              ></i>
            </template>
          </el-input>
          <el-select
            class="hospital"
            v-model="tableSearch.hospital_area_id"
            @change="hospitalChe"
            placeholder="请选择"
          >
            <el-option
              v-for="item in hospitalList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form>
      </div>
      <div class="serach-right">
        <el-button class="color-main" @click="back" type="primary">
          返回
        </el-button>
      </div>
    </div>

    <div class="table" ref="tableBoxRef">
      <el-table
        v-loading="dataListLoading"
        :data="tableData"
        class="table-ellipsis"
        v-if="istable"
        size="small"
        border
        :max-height="tableHei"
      >
        <el-table-column prop="bed_no" label="模板名称" />
        <el-table-column prop="hospital.name" label="备注" />
        <el-table-column prop="area.name" label="创建人" />
        <el-table-column prop="remark" label="创建日期" width="160" />
        <el-table-column fixed="right" label="操作" width="100">
          <template #default="scope">
            <el-button type="text" size="small" @click="modParient(scope.row)">
              <i class="txIons modify"></i>
            </el-button>
            <el-button type="text" size="small" @click="delParient(scope.row)">
              <i class="txIons del"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          :currentPage="pageData.currentPage"
          :page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, defineExpose, nextTick } from 'vue'
import service from '@/utils/request'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'

const userInfo = localStorage.getItem('ms_userData')
const dataListLoading = ref(false)
const istable = ref(true)
const pageData = reactive({
  currentPage: 1,
  pageSize: 10,
  totalSum: 0,
})
const tableSearch = reactive({
  spital_area_id: userInfo.hospital,
  bed_area_id: '',
})
const tableData = ref([])
const tableBoxRef = ref(null)
const tableHei = ref(0)
const router = useRouter()

onMounted(async () => {
  istable.value = false
  tableHei.value = tableBoxRef.value.clientHeight - 40
  nextTick(() => {
    istable.value = true
  })
  await getHospitalList(0)
  initData()
})
const back = () => {
  router.go(-1)
}
const initData = () => {
  handleSearch()
}
defineExpose({
  initData,
})
const handleSearch = () => {
  pageData.currentPage = 1
  getDataList()
}
const handleSizeChange = (val) => {
  pageData.pageSize = val
  getDataList()
}
const handleCurrentChange = (val) => {
  pageData.currentPage = val
  getDataList()
}
const getDataList = async () => {
  tableSearch.current = pageData.currentPage
  tableSearch.size = pageData.pageSize
  dataListLoading.value = true
  let res = await service.post('/api/bed/list', tableSearch)
  dataListLoading.value = false
  if (res.code === 0) {
    tableData.value = res.data.records
    pageData.totalSum = res.data.total
  }
}

const hospitalList = ref([])
const groupingList = ref([])
const getHospitalList = async (id) => {
  let res = await service.post('/api/hospital/list', {
    parent_id: id,
  })
  if (res.code === 0) {
    if (id == 0) {
      hospitalList.value = res.data
      if (!tableSearch.hospital_area_id) {
        tableSearch.hospital_area_id = res.data[0].id
        // tableSearch.hospital_area_name = res.data[0].name
        getHospitalList(res.data[0].id)
      }
    } else {
      groupingList.value = res.data
    }
  }
}
const hospitalChe = async (val) => {
  tableSearch.bed_area_id = ''
  getHospitalList(val)
}
const addRef = ref(null)

const modParient = (item) => {
  addRef.value.initData(item)
}
const delParient = (item) => {
  ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(async () => {
      let res = await service.post('/api/bed/delete', {
        id: item.id,
      })
      if (res.code === 0) {
        ElMessage.success(res.msg)
        getDataList()
      }
    })
    .catch(() => {})
}
</script>

<style scoped lang="scss">
.bedList-serach {
  padding-top: 2px;
}
.table {
  height: calc(100% - 46px);
}
.hospital {
  margin-right: 10px;
}
.tongluBox {
  margin-top: 16px;
}
</style>
